import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  UrlSegment,
  Route
} from '@angular/router';

import { CredentialsService } from './credentials.service';
import {AuthenticationService} from "@core/authentication/authentication.service";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate, CanLoad {
  private readonly defaultRoute:string = ''

  constructor(
    private readonly router: Router,
    private readonly credentialsService: CredentialsService,
    private readonly authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess(route.data?.roles, state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const url = `/${segments.map(s => s.path).join('/')}`;
    return this.checkAccess(route.data?.roles, url);
  }

  private checkAccess(roles: string[] | undefined, redirectUrl: string): boolean {
    // USER role is UNDEFINED -> logout
    if (!!this.credentialsService.credentials && !this.credentialsService.credentials.role) {
      this.authenticationService.logout()
      return false
    }

    // Route NOT PROTECTED
    if (!roles || roles.length === 0) return true

    // User AUTHENTICATED
    if (this.credentialsService.isAuthenticated()) {
      if (roles.includes(this.credentialsService.credentials.role)) {
        return true
      }
    }

    // Route NOT AUTHORIZED -> redirect to default route
    this.redirectToDefault(redirectUrl)
    return false
  }


  redirectToDefault(redirectUrl: string): boolean{
    this.router.navigate(
      [this.defaultRoute],
      { queryParams: { redirect: redirectUrl }, replaceUrl: true }
    );
    return false
  }
}
