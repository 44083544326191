import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { RouteReusableStrategy } from './route-reusable-strategy';
import { HttpService } from './http/http.service';
import {AuthenticationTokenInterceptor} from "@core/authentication/authentication-token.interceptor";
import {AuthenticationService} from "@core/authentication/authentication.service";
import {CredentialsService} from "@core/authentication/credentials.service";
import {I18nService} from "@core/i18n.service";
import {HttpCacheService} from "@core/http/http-cache.service";
import {ApiPrefixInterceptor} from "@core/http/api-prefix.interceptor";
import {CacheInterceptor} from "@core/http/cache.interceptor";
import {ErrorHandlerInterceptor} from "@core/http/error-handler.interceptor";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    RouterModule
  ],
  exports: [
  // export * from './authentication/authentication.service';
  // export * from './authentication/credentials.service';
  // export * from './authentication/authentication.guard';
  // export * from './i18n.service';
  // export * from './http/http.service';
  // export * from './http/http-cache.service';
  // export * from './http/api-prefix.interceptor';
  // export * from './http/cache.interceptor';
  // export * from './http/error-handler.interceptor';
  // export * from './route-reusable-strategy';
  // export * from './until-destroyed';
  ],
  providers: [
    AuthenticationService,
    CredentialsService,
    I18nService,
    HttpService,
    HttpCacheService,
    ApiPrefixInterceptor,
    CacheInterceptor,
    ErrorHandlerInterceptor,
    RouteReusableStrategy,
    {
      provide: HttpClient,
      useClass: HttpService
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationTokenInterceptor,
      multi: true
    }
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}
