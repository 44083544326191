import {Injectable, isDevMode} from '@angular/core';
import {BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Participant} from "@app/shared/models/Participant";
import {Survey} from "@app/shared/models/Survey";
import {catchError, filter, map, shareReplay, switchMap, tap} from "rxjs/operators";
import {isNaN, toNumber} from "lodash-es";
import {AuthenticationService} from "@core/authentication/authentication.service";
import {Configuration} from "@shared/models/Configuration";
import {Credentials, CredentialsService} from "@core/authentication/credentials.service";


export type RequestWitProgress<T> = {percentage: number, body: T}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  public participant_survey$: ReplaySubject<Survey[]>

  public participantSurveyRefresh$: BehaviorSubject<void>
  public participantSurvey$: Observable<Survey[]>
  public initialSurvey$: ReplaySubject<Survey>
  public hasCompletedInitialSurvey$: BehaviorSubject<boolean>
  public isParticipantSurveyLoading$: Observable<boolean>

  public participant_surveyStatus: 'empty'|'loading'|'completed'|'error'
  public configuration: any
  public config$: Observable<Configuration>

  constructor(
    public client: HttpClient,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
  )
  {
    this.participant_survey$ = new ReplaySubject<Survey[]>(1)
    this.participantSurveyRefresh$ = new BehaviorSubject<void>(null)
    this.participant_surveyStatus = 'empty'

    this.config$ = this.client.get<Configuration>(`/configuration`).pipe(
      filter((resp:any)=>!!resp),
      map((configuration: any)=>{
        return {
          ...configuration,
          limesurveyUrl: isDevMode()
            ? configuration.limesurveyUrl.replace('http://limesurvey', 'http://localhost:8003')
            : configuration.limesurveyUrl
        } as Configuration
      }),
      shareReplay(1)
    )

    this.initialSurvey$ = new ReplaySubject<Survey>(1)

    this.participantSurvey$ = combineLatest([this.participantSurveyRefresh$, this.credentialsService.change$]).pipe(
        filter(([_, credentials]: [void, Credentials]) => !!credentials?.participant_id),
        switchMap(([_, credentials]: [void, Credentials])=>
            this.client.get<Survey[]>(`/limesurvey/participant/${credentials.participant_id}/survey/`).pipe(
                tap((s: Survey[])=>{this.hasCompletedInitialSurvey$.next(true)}),
                catchError((e: HttpErrorResponse): Observable<any> => {
                  if(e instanceof HttpErrorResponse && e.status == 409){
                    this.hasCompletedInitialSurvey$.next(false)
                    this.initialSurvey$.next(e.error?.initialSurvey)
                    console.log('initialSurvey 409')
                    return of(undefined)
                  }
                  else return throwError(e)
                }),
            )
        ),
        shareReplay(1)
    )

    // this.participantSurvey$ = this.participantSurveyRefresh$.pipe(
    //   switchMapTo(this.credentialsService.change$),
    //   filter((credentials: Credentials) => !!credentials?.participant_id),
    //   switchMap((credentials: Credentials)=>
    //     this.client.get<Survey[]>(`/limesurvey/participant/${credentials.participant_id}/survey/`)
    //   ),
    //   catchError((e: HttpErrorResponse): Observable<any> => {
    //     if(e instanceof HttpErrorResponse && e.status == 409){
    //       this.hasCompletedInitialSurvey$.next(false)
    //       this.initialSurvey$.next(e.error?.initialSurvey)
    //       return of(undefined)
    //     }
    //     else return throwError(e)
    //   }),
    //   shareReplay(1)
    // )

    this.hasCompletedInitialSurvey$ = new BehaviorSubject<boolean>(true)

    this.isParticipantSurveyLoading$ = merge(
        this.participantSurveyRefresh$.pipe(map(() => true)),
        this.participantSurvey$.pipe(map(() => false)),
    );
  }

  public get_participants_csv(): Observable<string>{
    return this.client.get('/limesurvey/participant', {
    // return this.client.get('/limesurvey/csv', {
      responseType: 'text',
      headers: {'Accept': 'text/csv'}
    })
  }

  public get_participant_survey(participant_id:number, refresh:boolean=false): Observable<Survey[]>{
    this.participant_surveyStatus = 'loading'
    let options = refresh ? {params: {refresh: 'true'}} : {}

    return this.client.get(`/limesurvey/participant/${participant_id}/survey/`, options)
      .pipe(
        tap(
          (s: Survey[])=>{
            this.participant_survey$.next(s)
            this.participant_surveyStatus = 'completed'
          }
        ),
        catchError((e: any): Observable<any> => {
          this.participant_survey$.next(e)
          this.participant_surveyStatus = 'error'
          return throwError(e)
        })
      )
  }

  public update_participant_survey(participantID: number, surveyID: string): Observable<Survey>{
    return this.client.get(`/limesurvey/participant/${participantID}/survey/${surveyID}`).pipe(
        tap((survey: Survey)=>{this.participantSurveyRefresh$.next()})
    )
  }

  public get_participant_detail(participant_id:number): Observable<Participant>{
    return this.client.get(`/limesurvey/participant/${participant_id}/`) as Observable<Participant>
  }

  public activate_user(token:string){
    return this.client.get(`/auth/activation/${ token }`)
  }

  public delete_user(user_id:number){
    return this.client.delete(`/auth/user/${ user_id }`)
  }

  public get_csv(){
    return this.client.get('/limesurvey/csv', {responseType: 'text'})
  }

  public pixel_sent(participant_id: number):Observable<any>{
    return this.client.post(`/pixel_sent/${participant_id}/`, {})
  }

  isDummyEmail(email:string){
    return !!email && email.endsWith('.dummy')
  }

  isDummyName(name:string) {
    return !!name && !isNaN(toNumber(name))
  }
}
