import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {merge} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';

import {environment} from '@env/environment';
import {I18nService,} from '@app/core/i18n.service';
import {untilDestroyed} from '@app/core/until-destroyed';
import {FacebookPixelService} from "@app/feature/facebook/facebook-pixel/facebook-pixel.service";
import {BackendService} from "@core/backendService/backend.service";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly titleService: Title,
              private readonly translateService: TranslateService,
              private readonly i18nService: I18nService,
              private readonly backendService: BackendService,
              private readonly facebookPixelService: FacebookPixelService
  ) { }

  ngOnInit() {
    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd))


    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }



}
