import { Injectable } from '@angular/core';
import {combineLatest, merge, Observable, of, Subject, throwError} from 'rxjs';

import { Credentials, CredentialsService } from './credentials.service';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {catchError, map, tap} from "rxjs/operators";
import {environment} from "@env/environment";
import {ActivatedRoute, Router, RouterStateSnapshot} from "@angular/router";

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public logout$: Subject<void>
  public login$: Subject<void>
  public authChange$: Observable<void>

  constructor(
    private credentialsService: CredentialsService,
    public client: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    // private routerState: RouterStateSnapshot
  ) {
    this.logout$ = new Subject<void>()
    this.login$ = new Subject<void>()

    this.authChange$ = merge(
      this.login$,
      this.logout$,
    )
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    this.credentialsService.setCredentials();
    return <Observable<Credentials>>this.client.post(`/auth/login/`, context)
      .pipe(
        map((r:Credentials)=>{
            this.credentialsService.setCredentials(r, context.remember)
            return this.credentialsService.credentials
          }
        ),
        tap(()=>{ this.login$.next() })
      )
  }

  loginSocial(context: any): Observable<Credentials> {
    this.credentialsService.setCredentials();
    return <Observable<Credentials>>this.client.post(`/auth/social/login`, context)
      .pipe(
        map((r:Credentials)=>{
          this.credentialsService.setCredentials(r, true)
          return this.credentialsService.credentials
        }),
        tap(()=>{ this.login$.next() })
      )
  }

  refresh(): Observable<any>{
    let credentials = this.credentialsService.credentials
    let refresh = credentials ? credentials.refresh : null
    return this.client.post(`/auth/refresh/`, {refresh}).pipe(
      tap((r:any)=>{
        r.access
          ? this.credentialsService.setCredentials({...credentials, ...r})
          : this.logout().subscribe()
      }),
      catchError((error: HttpErrorResponse) => {
        this.logout().subscribe()
        return throwError(error)
      })
    )
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  // logout(): Observable<boolean> {
  //   // Customize credentials invalidation here
  //   this.credentialsService.setCredentials();
  //   return of(true);
  // }
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    // this.auth$.next(this.credentialsService.credentials)
    this.router.navigate(['/'])
    this.logout$.next()
    return of(true);
  }


  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  register(user:any): Observable<any> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    let registrationURl = `/auth/registration/`
    let activateURl = `${environment.serverUrl}/auth/registration/confirm/`

    return this.client.post(registrationURl, user).pipe(tap((r)=>{return r}))
  }

  redirectAfterAuthChange(){
    let credentials = this.credentialsService.credentials;
    let redirectUrl = this.route.snapshot.queryParams.redirect

    this.router.navigate(
      [
        redirectUrl
          ? redirectUrl
        : !credentials
          ? ''
        : credentials.role === 'user'
          ? 'dashboard/participant'
        : credentials.role === 'collaborator'
          ? 'dashboard/collaborator'
        : 'dashboard/admin'

      ],
      {replaceUrl: true}
    );
  }

}

