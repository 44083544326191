import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from "@core/authentication/authentication.guard";
import {MaintenanceGuard} from "@app/feature/maintenance/maintenance.guard";


const routes: Routes = [
  {
    path: 'dashboard/participant',
    loadChildren: () => import('./pages/user/user-pages.module').then(m => m.UserPagesModule),
    canActivate: [AuthenticationGuard, MaintenanceGuard],
  },
  {
    path: 'dashboard/admin',
    loadChildren: () => import('./pages/admin/admin-pages.module').then(m => m.AdminPagesModule),
    canActivate: [AuthenticationGuard, MaintenanceGuard],
  },
  {
    path: 'dashboard/collaborator',
    loadChildren: () => import('./pages/collaborator/collaborator-pages.module').then(m => m.CollaboratorPagesModule),
    canActivate: [AuthenticationGuard, MaintenanceGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./feature/maintenance/maintenance-routing.module').then(m => m.MaintenanceRoutingModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/public/public-pages.module').then(m => m.PublicPagesModule),
  },
  // Fallback when no prior route is matched
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "top",
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
