import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FlashMessageComponent} from "@shared/partials/flash-message/flash-message.component";
import {NavSpacerModule} from "@shared/helpers/nav-spacer/nav-spacer.module";

@NgModule({
  declarations: [FlashMessageComponent],
  exports: [FlashMessageComponent],
  imports: [
    CommonModule,
    NavSpacerModule
  ]
})
export class FlashMessageModule { }
