import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {first, map} from "rxjs/operators";
import {MaintenanceService} from "@app/feature/maintenance/maintenance.service";


@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly maintenanceService: MaintenanceService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.maintenanceService.isMaintenance$.pipe(
            first(),
            map((isMaintenance:boolean) => isMaintenance===true ? this.redirectToMaintenance(state.url) : true)
        )
    }

    private redirectToMaintenance(redirectUrl: string): boolean{
        this.router.navigate(['/maintenance'])
        return false
    }
}
