import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NavSpacerComponent} from "@app/shared/helpers/nav-spacer/nav-spacer.component";

@NgModule({
  declarations: [NavSpacerComponent],
  imports: [
    CommonModule,
  ],
  exports : [NavSpacerComponent]
})
export class NavSpacerModule { }
