import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable, throwError} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";

import {AuthenticationService} from "@app/core/authentication/authentication.service";
import {CredentialsService} from "@core/authentication/credentials.service";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenInterceptor implements HttpInterceptor {

  protected refreshInProgress: Observable<any>

  constructor(public credentialService: CredentialsService, public auth: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isRefreshRequest = !!request.body && 'refresh' in request.body
    let isAuthenticated = !!this.credentialService.credentials

    // Request should not manipulated, return as it is
    if(!isAuthenticated || isRefreshRequest) return next.handle(request)

    // Clone request and add authorization header
    return next.handle(this.cloneWithToken(request))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // With 401 Token should be expired, try refresh
          if (error.status === 401) {
            // To avoid multiple concurrent refresh share the same HttpRequest instance
            if (!this.refreshInProgress) this.refreshInProgress = this.auth.refresh()

            // After refresh return a clone of the original HttpRequest instance with the new token
            return this.refreshInProgress.pipe(
              switchMap((newToken: string)=>{
                this.refreshInProgress = null;
                return next.handle(this.cloneWithToken(request))
              })
            )
          }

          // Return any error other than 401
          return throwError(error)
        })
      )
  }

  // Clone the provided request adding authorization header to the clone
  cloneWithToken(request:HttpRequest<any>, token?:string):HttpRequest<any>{
    let credentials = this.credentialService.credentials

    let h = request.headers.set('authorization', `Bearer ${ credentials.access }`)
    let headers = h.set('ngsw-bypass', 'true')

    return request.clone({headers})
  }


}
