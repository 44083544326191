import { Injectable } from '@angular/core';
import {ReplaySubject} from "rxjs";


/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class CredentialsService {
  constructor() {
    this.getCredentialsFromBrowser()
  }

  private _credentials: Credentials|undefined;
  public change$: ReplaySubject<Credentials> = new ReplaySubject<Credentials>(1)

  getCredentialsFromBrowser(){
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
    this.change$.next(this.credentials)
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean { return !!this.credentials; }

  decodeToken(token:string):DecodedToken|null {
    try{
      let split = token && token.split('.')[1]
      return <DecodedToken>JSON.parse(window.atob(split))
    }
    catch{ return null }
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | undefined {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    if (credentials) {
      let decoded = this.decodeToken(credentials.access)
      this._credentials = {
        ...credentials,
        ...(decoded || {} as Credentials)
        // username: decoded.username,
        // user_id: decoded.user_id,
        // participant_id: decoded.participant_id,
        // role: decoded.role,
        // created: decoded.created,
        // first_name: decoded.first_name,
        // last_name: decoded.last_name,
        // facebook_pixel_click_id: decoded.facebook_pixel_click_id,
      } || undefined

      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(this._credentials));
    }
    else {
      this._credentials = undefined
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }

    this.change$.next(this.credentials)

  }

}

export type UserRole = "user"|"collaborator"|"staff"|"admin"

export interface Credentials {
  access: string
  refresh: string
  username?: string
  user_id?: number
  participant_id?: number
  role?: UserRole
  created?: string
  first_name?: string
  last_name?: string,
  facebook_pixel_click_id?: string
  facebook_utm_source?: string
  facebook_id?: string
}

export interface DecodedToken extends Credentials{
  token_type: string
  exp: string
  jti: string
}

const credentialsKey = 'credentials';
