import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError, map, shareReplay} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {BackendService} from "@core/backendService/backend.service";


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  constructor(private readonly backendService: BackendService) { }

  isMaintenance$: Observable<boolean> = this.backendService.config$.pipe(
      map(() => false),
      catchError((error: HttpErrorResponse) => of(error.status === 503)),
      shareReplay(1)
  );
}
